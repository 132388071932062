import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo, ReactElement } from 'react';
import Link, { Props, SystemLinkProps, LinkProps } from './Link';
import type { SystemLinkTo } from './types';

type LinkButtonProps = Omit<Props, 'ref'>;

function LinkButton(props: Omit<LinkProps, 'ref'>): ReactElement;
function LinkButton(props: Omit<SystemLinkProps, 'ref'>): ReactElement;
function LinkButton({ to, children, className = '', ...attributes }: LinkButtonProps) {
  return (
    <Link to={to as SystemLinkTo} className={`${btnStyles.btn} ${className}`} {...attributes}>
      <span className="btn-cnt">{children}</span>
    </Link>
  );
}

export default memo(LinkButton) as typeof LinkButton;
