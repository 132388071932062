import toastStyles from 'components/objects/toast/Toast.module.scss';
import { useEffect } from 'react';
import { enablePageScroll, disablePageScroll, addFillGapSelector, removeFillGapSelector } from 'scroll-lock';
import { isBrowser} from 'utils/detections';


const fillGapSelectors = [
  '#at-share-dock',
  '#at-expanding-share-button',
  '.atss-right',
  '.' + toastStyles.container,
];

export function useBodyScrollToggle(disable: boolean) {
  
  isBrowser && useEffect(() => {
    if (!disable)
      return;

    // disablePageScroll();
    addFillGapSelector(fillGapSelectors);
    return () => {
      // enablePageScroll();
      removeFillGapSelector(fillGapSelectors);
    };
  }, [disable]);
}
